
#fb-share-button {
	background: #3b5998;
	border-radius: 3px;
	font-weight: 600;
	padding: 5px 8px;
	display: inline-block;
	position: static;
}

#fb-share-button:hover {
	cursor: pointer;
	background: #213A6F
}

#fb-share-button svg {
	width: 18px;
	fill: white;
	vertical-align: middle;
	border-radius: 2px
}

#fb-share-button span {
	vertical-align: middle;
	color: white;
	font-size: 14px;
	padding: 0 3px
}

* {
	margin:0;
	padding:0;
	box-sizing: border-box;
}

html {
	height:100%;
	text-decoration:none;
}

body{
	margin: 0 auto;
	background-color:#000000;
	font-family: 'Montserrat', sans-serif;
}


html, body, #app, #app > div {
	height: 100%
}

h1{
	padding:10px;
}

.column{
	display:flex;
	flex-direction:column;
}

.row{
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	width: 8%;
}

.part-title{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 8%;
}

.title-black{
	color:black;
}

.header {
	color:white;
	min-height: 95vh;
	overflow: hidden;
	position:relative;
	display:flex;
	flex-direction: column;
	z-index:4;
	background: #000000;
}

.Connect-VideoView--icons-7:hover{
	color:gray
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -100;
  width:100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow:hidden;
}

a {
	text-decoration:none;
	color:white;
}

header .menu-logo-wrapper{
	display:flex;
	align-items:center;
	padding:1rem;
	border-bottom: 1px solid white
}

header menu{
	width:100%;
	display: flex;
	justify-content: flex-end;
	font-family: 'Montserrat';
	font-weight: 600;
	font-size:.8em;
}

header .menu-logo{
	width: 12%;
}

header menu ul{
	display:flex;
	justify-content:center;
}

header menu ul li{
	flex-direction: row;
	padding:0 40px 0 40px;
	color:white;
	list-style-type: none;
}

header menu ul li:hover a{
	border-bottom:1px solid #fff;
}

header .header-logo{
	width: 200px
}


header .header-title-wrapper .header-title{
	font-size:5em;
}

.button{
	padding:12px;
	font-size:.8rem;
	border-style: solid;
	border-width: 1px;
	border-color:white;
	cursor:pointer;
}

header .header-social-media-icons-wrapper{
	display:flex;
	min-height: 95vh;
}

header .header-social-media-icons-wrapper .header-title-wrapper{
	display: flex;
	align-items: center;
	width: 92%;
	justify-content: center;
	padding-right: 8%;
}

header .header-social-media-icons-wrapper .header-social-media-icons{
	padding:8px;
}

header .header-social-media-icons-wrapper .header-social-media-icons img{
	height:35px;
}

.projects{
	background-color:rgb(163,225,198);
	min-height: 95vh;
	display:flex;
	position: relative;
	z-index:2;
}
.projects .projects-wrapper{
	display:flex;
	justify-content: center;
	width:100%;
}

.projects .projects-wrapper .projects-part{
	width:50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.projects .projects-wrapper .projects-part-left-wrapper{
	padding:10%;
}

.projects .projects-wrapper .projects-part .projects-part-left-wrapper .spinning-logo{
	width:250px;
}

.projects .projects-wrapper .projects-part-right-wrapper{
	padding:10%;
	text-align:left;
	line-height: 1.8rem;
	font-size:.9rem;
}

.projects .projects-wrapper .projects-part .projects-part-right-wrapper .projects-description .right{
	width:250px;	
}

.about{
	display:flex;
	background-color:white;
	width:100%;
	background-color:white;
}

.about-part-content{
	margin-right:8%;
}

.title{
	font-weight:600;
	font-size:1em;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.about .text-wrapper{
	padding: 5% 25%;
	text-align:center;
}

.about .text-wrapper .text p{
	padding:2%;
	font-weight: 500;
}


.about .about-part-content .half-border{
	border:1px solid black;
	width: 35%;
	margin: 0 auto;
}

.about .about-part-content .half-border-width{
	border:5px solid black;
	width: 35%;
	margin: 0 auto;
}


.about .about-part-content .logo-wrapper{
	display:flex;
	flex-direction: row;
	padding: 5% 20%;
}

.about .logo-wrapper .logo{
	width:33%;
	text-align: center;
	font-weight: 600;
}

.about .logo-wrapper .logo .description{
	padding:10px;
}

.about .logo-wrapper .logo .logo-image {
	height: 90px;
	padding: 15px;
}

.details{
	background-color:black;
	display:flex;
}

.part-title {
	color:white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 8%;
}

.details .part-content .how-to{
	padding: 150px 180px;
}

.details .part-content .how-to .step{
	display:flex;
	padding: 15px;
}

.details .part-content .how-to .step .number {
	display: flex;
	align-items: center;
	width:14%;
}

.details .part-content .how-to .step .number .logo-image{
	height:50px;
}

.details .part-content .how-to .step .command {
	display: flex;
	align-items: center;
	width:25%;
}
.details .part-content .how-to .step .command h2{
	color:rgb(163,225,198);
}
.details .part-content .how-to .step .description{
	display: flex;
	align-items: center;
	color:white;
	width:53%;
}

.details .part-content .how-to {
	color:rgb(163,225,198);
}

.part-title .title .title-white{
	color:white;
}

.details .details-wrapper .details-item{
	padding:50px;
}

.details .details-wrapper .details-item img{
	width:100%;
}

.contact{
	background-color:black;
	color:white;
	display:flex;
}

.part-title{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 8%;
}

.contact .quarter-border{
	border:1px solid white;
	width: 5%;
	margin: 0 auto;
	margin:20px;
}

.contact .part-content{
	margin-right:8%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;
}

.contact .contact-decoration{
	position:absolute;
	right:0px;
}

.contact .contact-decoration img{
	position:absolute; 
	right:0px;
	top:50px;
}

.contact .contact-description{
	margin-top:25px;
	margin-bottom:40px;
	font-size:.8rem;
	line-height: 1.5rem;
	color:white;
	text-align: center;
	padding: 0 50px;
}

.contact .title-line-center{
	border-color:white;
}

.contact .title{
	color:white;
}

.contact-subsection{
	display:flex;
	width:100%;
}

.contact-subsection .contact-subsection-left{
	width:100%;
	justify-content:center;
	padding: 0 10%;
}

.contact-subsection .contact-subsection-left-wrapper{
	width:100%;
}

.contact-subsection input{
	width: 100%;
	padding-bottom:12px;
	border-bottom: 1px solid #ccc;
	outline:0;
	border:0;
	color: white;
	background: transparent;
	box-sizing: border-box;	
}

.input-row{
	display:flex;
}

.input-element{
	padding:0 10px;
	width:100%;
}

.contact-subsection input{
	margin-top:40px;
}

.contact-subsection ::placeholder{
	color:rgba(255,255,255,.3);
}

.contact-subsection textarea {
  resize: none;
  width:100%;
	height: 120px;
	border-bottom: 1px solid #ccc;
	background:transparent;
	margin-top:80px;
	color:white;
	outline:none;
	padding:5px;
}

.contact-subsection .submit-wrapper{
	color:white;
	margin-top:10px;
	display:flex;
	justify-content:center;
}

.contact-subsection .contact-section-logo-wrapper{
	display: flex;
	justify-content: center;
	margin:50px;
}


.contact-subsection .contact-section-logo{
	width:150px;
	height:150px;
}

.contact-subsection .submit-wrapper .button{
	color:white;
	margin-top:20px;
	text-align:right;
}


.contact-subsection .contact-subsection-right{
	width:50%;
	color:white;
	display:flex;
	justify-content:center;
	font-size:.75rem;
}

.contact-subsection .contact-subsection-right li{
	font-size:1.3rem;
}

.contact-subsection .contact-subsection-right ul li:nth-child(2)  {
	padding-top:120px;
}

.contact-subsection .contact-subsection-right li .info{
	font-size:.9rem;
	padding-top:60px;
	line-height:1.5rem;
}

footer{
	height:60px;
	background-color:black;
}

footer .border{
	width:90%;
	border-top: 1px solid #ccc;
	margin:0 auto;

}

footer .footer-content-wrapper{
	display:flex;
	justify-content: space-between;
	flex-wrap:wrap;

	padding-top:20px;
	color:white;	
}

footer .footer-content-wrapper .text{
	font-size:.6rem;
	position:relative;
	top:10px;
	margin-right:25px;
}

footer .footer-content-wrapper .footer-logo{
	width:150px;
}

footer .footer-content-wrapper .icons{
	width:50%;
}

.footer-social-media-icons-wrapper{
	display:flex;
	justify-content:flex-end;
	position:relative;
	top:-10px;
}

.footer-social-media-icons-wrapper img{
	height:30px;
	
}

.mobile-nav{
	display:none;
}
.social{
	display:flex;
}

.close{
	display:initial;
	
}

.small_video{
	cursor:pointer;
}

.contact{
	padding-top:0px
}

.contact-video{
	display:flex;

}

.contact-video video{
	height:500px;
	padding:10%;
}

@media (max-width:1100px){
	.details .part-content .how-to{
		padding: 150px 80px;
	}
	
}
@media (max-width:700px){

	#lollipop{
		display: none;
	}

	.contact-video{
		display:none;
	}

	.input-row{
		flex-wrap: wrap;
	}

	.contact-subsection .contact-subsection-left{
		padding:0 10%;
	}

	header .header-social-media-icons-wrapper .header-title-wrapper{
		padding:0;
		width:100%;
	}

	.projects .projects-wrapper .projects-part:first-child{
		display:none;
	}
	.header-social-media-icons-wrapper .row{
		display:none;
	}

	header{
		height:100%;
	}
	
	header menu, header .menu-logo-wrapper, header .header-background-video{
		display:none;
	}

	header .menu-logo{
		width: auto;
	}

	footer .footer-content-wrapper{
		flex-direction:column;
	}

	.projects .projects-wrapper .projects-part-right-wrapper{
		padding: 0 10% 10% 10%;
		
	}
	
	.projects .video-container{
 		flex-direction:column;
 	}	
			
	.projects .video-container .video{
			width: 100%;
			height:300px;
	}
	
	.projects .projects-wrapper{
		flex-wrap:wrap;
	
	}

	.projects .projects-wrapper .projects-part .projects-part-right-wrapper .projects-description{
		text-align: center;
		
	}
	
	.projects .projects-wrapper .projects-part{
		width:100%;
	}

	.part-title{
		display:none;
	}

	.about .about-part-content{
		margin:0;
	}

	.about .about-part-content .text-wrapper{
		padding: 5% 8%;
	}

	.about .about-part-content .logo-wrapper{
		padding-left: 10%;
		padding-right: 10%;

		flex-direction: column;
	}

	.details .part-content .how-to .step{
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.details .part-content .how-to {
		padding:5% 0%;
	}

	.details .part-content .how-to .step .number,	.details .part-content .how-to .step .command {
		display: flex;
		align-items: center;
		width:auto;
		padding:10px;
	}
	
	.about .logo-wrapper .logo{
		width:100%;
		text-align: center;
		font-weight: 600;
		padding:25px;
	}
	
	.services .services-wrapper{
		padding:30px;
		display:flex;
		justify-content:center;
		align-items:center;
		flex-wrap:wrap;
	}

	.mobile-nav{
		display:initial;
		top:0px;
		position:fixed;
		height:50px;
		width:100%;
		background-color:rgba(0,0,0,.8);
		z-index:1000;
	}

	.mobile-nav .menu_button{
		position:absolute;
		height:25px;
		right:13px;
		top:13px;
		cursor:pointer;
	}

	.mobile-nav .drop-down-menu{
		position:absolute;

		top:50px;
		text-align:center;
		
	}

	.mobile-nav .drop-down-menu ul{
		list-style-type: none;
		padding:0px;
		background-color:rgba(0,0,0,.5);
	}

	.mobile-nav .drop-down-menu ul li{
		color:white;
		position:relative;
		height:40px;
		width:100%;
		list-style-type: none;
		padding-top:10px;
	}

	.mobile-nav .drop-down-menu{
		position:relative;
		height:20px;

		visibility:hidden;
		
	}

	.contact .contact-decoration img{
		display:none;
	}

	.contact-subsection .contact-subsection-left{
		width:100%;
	}

	.contact-subsection .contact-subsection-right{
		display:none;
	}

	.contact .part-content {
		margin:0;
	}
	
	.footer-content-wrapper{
			display:flex;
			flex-direction:column;
			justify-content:center;
			align-items:center;
	}
	
	.footer-content-wrapper .icons{
		margin-top:20px;
		order:1;
		
	}
	
	 .footer-social-media-icons-wrapper{
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-bottom:20px;
		 
	 }
	
	footer .footer-social-media-icons-wrapper .text{
			margin:0px;
			order:3;
	}
	
	
	.footer-content-wrapper .logo{
		text-align:center;
		order:1;
		
	}
	
	.column .button{
		display:none;
	}
	
	.video-wrapper{
		width:90%;
	}
	
	.close{
		display:none;
	}

}

.allthefields{
	width:300px;
	height:200px;
	background-color:rgba(0,0,0,.8);
	position:fixed;
	top:30%;
	z-index:1;
	
	color: white;
    display: flex;
    justify-content: center;
    align-items: center;
	border:1px solid #fff;
	
}

.errorwrapper{
	display: flex;
    justify-content: center;
    align-items: center;
	height:100%;
	width:100%;
	
	position:fixed;
	
	visibility:hidden;
}

.error-box-close{
	position: absolute;
    right: -5px;
    top: -10px;
    color: white;
	cursor:pointer;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

/*opening page*/








